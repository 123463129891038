
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Tagify from '@yaireo/tagify';

interface IFormVals {
  Title: string;
  is_active: string;
  itemAccount: string;
}

export default defineComponent({
  name: "add-account-group",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);

    const formVals = ref<IFormVals>({
      Title: "",
      is_active: "",
      itemAccount: "",
    });

    const validationSchema = Yup.object().shape({
      Title: Yup.string().required("Grup Adı girilmemiş"),
      is_active: Yup.string().required("Durum seçilmemiş"),
     // itemAccount: Yup.string().required("Acente seçilmemiş"),
    });

    store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
    const myAccounts = computed(() => {
      const tmp = store.getters.accountList;
      //console.log(tmp)
      return tmp;
    });

    function onAccountChange(e) {
      let tmpObj = JSON.parse(e.target.value);
      formVals.value.itemAccount = tmpObj;
    }

    const myTagsAccountGroup = computed(() => {
      if (myAccounts.value.data !== undefined) {
        let tmpList = [];
        for (let i = 0; i < myAccounts.value.data.length; i++) {
          tmpList[i] = {
            value: (myAccounts.value.data[i].Title).trim(),
            title: (myAccounts.value.data[i].Title).trim(),
            //type: 'Accounts',
            ID: myAccounts.value.data[i].ID,
          };

        }
        setTagInputAccountGroups(tmpList)
        return tmpList;
      } else {
        return [];
      }
    });

    let tagify2;
    const setTagInputAccountGroups = (wList) => {
      var input = document.querySelector('input[name=myTagsInputAccounts3]');
// init Tagify script on the above inputs
      tagify2 = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 5,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        }
      });


    }

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      const payload1 = {
        Title: formVals.value.Title,
        is_active: formVals.value.is_active,
        Accounts: JSON.stringify(formVals.value.itemAccount),
      }

      store.dispatch(Actions.ACCOUNT_GROUP_INSERT, payload1).then(() => {
        store.dispatch(Actions.ACCOUNT_GROUP_LIST, {});
        Swal.fire({
          text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.", icon: "success", buttonsStyling: false,
          confirmButtonText: "Geri Dön!", customClass: { confirmButton: "btn fw-bold btn-light-primary", },
        }).then(function () {
          submitButtonRef.value.disabled = false;
          submitButtonRef.value.setAttribute("data-kt-indicator", "off");
          hideModal(modalRef.value);
        });
      }).catch(() => {
        Swal.fire({
          text: store.getters.getAccountGroupErrors[0], icon: "error", buttonsStyling: false, confirmButtonText: "Tekrar dene!",
          customClass: { confirmButton: "btn fw-bold btn-light-danger",},
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });


    }


    return {
      submitButtonRef,
      formVals,
      validationSchema,
      submit,
      modalRef,
      onAccountChange, myTagsAccountGroup,
    };


  },
});
