
import {computed, defineComponent, ref, watch} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Tagify from '@yaireo/tagify';
import {dateConvert} from "@/core/filters/datatime";
import lib_Tools, {libTools} from "@/core/appcore/libs/Lib_Tools";

interface newItemData {
  itemAccount: string;
  itemTitle: string;
  itemIs_active: string;
}

export default defineComponent({
  name: "add-account-group",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    itemID: String,
  },

  /*watch: {
    itemID() {
      const payloadit = {
        "ID": this.itemID
      };

      store.dispatch(Actions.ACCOUNT_GROUP_DETAILS, payloadit);
    },
  },*/

  setup(props) {
    watch(
        () => props.itemID,
        () => {
          store.dispatch(Actions.ACCOUNT_GROUP_DETAILS, {'ID': props.itemID});
        }
    );

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);

    const newItemData = ref<newItemData>({
      itemAccount: "",
      itemTitle: "",
      itemIs_active: "",
    });

    const myDetails = computed(() => {
      const tmpArry = store.getters.getAccountGroupDetails;
      setData(tmpArry);
      return tmpArry;

    })

    const setData = (tmpArry) => {
      if (tmpArry.ID !== undefined ) {
        newItemData.value.itemTitle = tmpArry.Title;
        newItemData.value.itemIs_active = tmpArry.is_active;
        newItemData.value.itemAccount = JSON.parse(tmpArry.Accounts);

      }
    }

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      const payload1 = {
        ID: props.itemID,
        Title: newItemData.value.itemTitle,
        is_active: newItemData.value.itemIs_active,
        Accounts: (typeof newItemData.value.itemAccount != "string") ? (JSON.stringify(newItemData.value.itemAccount)).replace(/\\/g, '') : '""',
      }
      store.dispatch(Actions.ACCOUNT_GROUP_EDIT, payload1)
          .then(() => {
            store.dispatch(Actions.ACCOUNT_GROUP_LIST, {});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getAccountGroupErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };


    store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
    const myAccounts = computed(() => {
      return store.getters.accountList;
    });



    let accTagAddStatus = false;

    const myTagsAccounts2 = computed(() => {
      if (myAccounts.value.data !== undefined && myDetails.value.ID) {
        let tmpList = [];
        let tmpSelList = [];
        let selData = JSON.parse(myDetails.value.Accounts);
        for (let i = 0; i < myAccounts.value.data.length; i++) {
          tmpList[i] = {
            value: (myAccounts.value.data[i].Title).trim(),
            title: (myAccounts.value.data[i].Title).trim(),
            //type: 'Accounts',
            ID: myAccounts.value.data[i].ID,
          };
          for (let is = 0; is < selData.length; is++) {
            if (selData[is].ID == myAccounts.value.data[i].ID) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              tmpSelList[is] = {

                value: (myAccounts.value.data[i].Title).trim(),
                title: (myAccounts.value.data[i].Title).trim(),
                //type: 'Accounts',
                ID: myAccounts.value.data[i].ID,
              };
            }

          }
        }
        if(accTagAddStatus==false){setTagInputUpdateAccounts2(tmpList);accTagAddStatus=true;}
        let tags = (document.getElementById('myTagsUpdateAccounts2') as HTMLInputElement);
        if(tags.value!==undefined && tags.value!='' )tagifyUpdateAccount2.removeAllTags();

        tmpSelList = libTools.method.getWithoutNull(tmpSelList);
        if(tmpSelList.length>0) {
          tagifyUpdateAccount2.addTags(tmpSelList);
        }
        return tmpList;
      } else {
        return [];
      }
    });

    let tagifyUpdateAccount2;
    const setTagInputUpdateAccounts2 = (wList) => {
      var input = document.querySelector('input[name=myTagsUpdateAccounts2]');
// init Tagify script on the above inputs
      tagifyUpdateAccount2 = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 5,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        }
      });

    }



    function onAccountChange2(e) {
      if (e.target.value !== "") {
        let tmpObj = JSON.parse(e.target.value);
        newItemData.value.itemAccount = tmpObj;
      } else {
        let tmpObj = "";
        newItemData.value.itemAccount = tmpObj;
      }
    }




    return {
      submitButtonRef,
      modalRef,
      myDetails,
      myTagsAccounts2,
      onAccountChange2,
      newItemData,
      submit,
      myAccounts,
    };


  },
});
